html {
  box-sizing: border-box;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background: rgb(240, 240, 240);
}

h1 {
  font-size: 1.5rem;
  font-weight: 500;
  width: 100%;
  text-align: center;
  color: #555555;
  margin-bottom: 0.2rem;
}

h3 {
  margin-top: 0;
  font-weight: 400;
  color: #888888;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main {
  flex: 1;
  width: 350px;
  margin: 2rem auto;
  box-shadow: 0px 0px 3px 0px rgba(70, 70, 70, 0.34);
}
